import style from './index.module.scss';
import { ButtonLink, IntrinsicProps, createComponent } from 'react-commons';

interface LoginButtonProps extends IntrinsicProps {
  loginOnClick?: boolean
}

export default createComponent<LoginButtonProps>('LoginButton', { style }, function LoginButton ({ className }, props) {

  return (
    <div 
      className={className}
    >
      <ButtonLink secondary href={'/account/login'}>Login</ButtonLink>
      <ButtonLink secondary href={'/account/register'}>Signup</ButtonLink>
    </div>
  );
});
