import UserAvatar from '@/components/UserAvatar';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { faCoins, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';
import { Button, ButtonLink, Buttons, createComponent, Flex, IconFA, If, Slot } from 'react-commons';

import style from '../index.module.scss';
import LoginButton from '@/components/LoginButton';
import Link from 'next/link';
import { useAuthMethods } from '@/lib/hooks/useAuthMethods';

export default createComponent('MobileUserStatus', { style }, function MobileUserStatus ({ className }) {
  const elRef = useRef<HTMLDivElement | null>(null);
  const [ authState ] = useAuthStore();
  const { logout } = useAuthMethods();

  return (
    <div className={className} ref={elRef}>
      {
        If(authState.user, () => (
          <Flex directionColumn>
            <Flex className='MobileUserStatus__User' container gap0 alignCenter>
              <Flex>
                <Link href={`/member/profiles/${authState.user.displayName}`}>
                  <div>
                    <UserAvatar 
                      medium
                      src={authState.user.avatarUrl} 
                      displayName={authState.user.displayName}
                    />
                  </div>
                </Link>
              </Flex>
              <Flex directionColumn>
                <Flex>
                  <p>{authState.user.displayName}</p>
                </Flex>
                <Flex>
                  {
                    If(authState.user.isPremiumUser, () => (
                      <img 
                        src='/images/shockwaveUnlimited.svg' 
                        style={{ width: 'auto', height: '28px' }} 
                        className='--marSm1__t'
                        alt='SWU Member'
                      />
                    )).Else(() => (
                      <ButtonLink 
                        href='/unlimited'
                        secondary
                        className='--marSm1__t'
                      >
                        Upgrade Plan
                      </ButtonLink>
                    )).EndIf()
                  }
                </Flex>
              </Flex>
            </Flex>
            <Flex container gap0>
              <Flex fit>
                <ButtonLink 
                  href={'/account/user/edit'} 
                  primary
                  className='Button--wideMobile'
                >
                  Edit Account
                </ButtonLink>
              </Flex>
              <Flex fit>
                <Button 
                  secondary 
                  onClick={logout}
                  className='Button--wideMobile'
                >
                  Log Out
                </Button>
              </Flex>
            </Flex>
          </Flex>
        ))
          .Else(() => (
            <LoginButton loginOnClick />
          ))
          .EndIf()
      }
    </div>
  );
});
