import { useState, useRef, useEffect } from 'react';
import { createComponent, Delete, EventListener, IntrinsicProps, toClassName } from 'react-commons';

import style from './index.module.scss';

export const popoverEvents = new EventListener<{
  open: (name: string) => void
  closeAll: () => void
}>();

const classStates = [
  'up',
  'right'
];

interface PopoverProps extends IntrinsicProps {
  up?: boolean
  right?: boolean
  name?: string
  onOpen?: () => void
  onClose?: () => void
}

export default createComponent<PopoverProps>('Dropdown', { classStates, style }, function Popover ({ mergeClassNames, slots }, props) {
  const ref = useRef(null);
  const ignoreNextClickRef = useRef(false);
  const [ isOpen, setIsOpen ] = useState(false);

  const className = mergeClassNames(
    'Popover',
    toClassName('Dropdown', { active: isOpen })
  );

  useEffect(() => {
    // Close when click outside
    const handleClick = (evt) => {
      if (ignoreNextClickRef.current) {
        ignoreNextClickRef.current = false;
        return;
      }

      const hasParent = evt.target.closest('.Popover') !== null;
      const hasOmit = evt.target.getAttribute('data-popover-omit') !== null;
      const hasOmitParent = evt.target.closest('[data-popover-omit]') !== null;

      if (!hasParent && !hasOmit && !hasOmitParent) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClick);

    // Open a specific popover
    const handleOpen = (name: string) => {
      if (name !== props.name) return;
      
      ignoreNextClickRef.current = true;
      setIsOpen(true);
    };
    popoverEvents.on('open', handleOpen);

    // Close when event is triggered
    const handleClose = () => {
      setIsOpen(false);
    };
    popoverEvents.on('closeAll', handleClose);

    return () => {
      document.removeEventListener('click', handleClick);
      popoverEvents.off('open', handleOpen);
      popoverEvents.off('closeAll', handleClose);
    };
  });

  useEffect(() => {
    if (isOpen && props.onOpen) props.onOpen();
    if (!isOpen && props.onClose) props.onClose();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isOpen ]);

  return (
    <div ref={ref} className={className}>
      <div className='Dropdown__Trigger' onClick={() => setIsOpen(!isOpen)}>
        {slots.trigger}
      </div>
      <div className='Dropdown__Menu'>
        <div className='Dropdown__Content'>
          <Delete onClick={() => setIsOpen(false)} />
          {slots.content}
        </div>
      </div>
    </div>
  );
});
