import { createComponent, IntrinsicProps, If, For } from '@common/util/templateHelpers';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Flex, Button, IconFA, Buttons } from 'react-commons';
import style from './index.module.scss';

interface PaginatorProps extends IntrinsicProps {
  currentPage: number
  numPages: number
  onPaginate: (currentPage: number, nextPage: number) => void
  numButtons?: number
  container?: boolean
  useFirstLast?: boolean
}

export default createComponent<PaginatorProps>('Paginator', { style }, function Paginator ({ className }, props) {
  const [ pages, setPages ] = useState([]);
  const [ hasPrevious, setHasPrevious ] = useState(false);
  const [ hasNext, setHasNext ] = useState(false);

  useEffect(() => {
    const checkHasPrevious = () => {
      return props.currentPage > 0;
    };

    const checkHasNext = () => {
      return props.currentPage < (props.numPages - 1);
    };

    const makePages = () => {
      const pages = [];
      for (let i = 0; i < props.numPages; i++) {
        pages.push(i);
      }

      const numButtons = props.numButtons || 5;
      const radius = Math.floor(numButtons / 2);

      let start = props.currentPage - radius;
      let end = props.currentPage + radius;

      while (start < 0) {
        start++;
        end++;
      }
      while (end - start < numButtons) {
        end++;
      }
      while (end > props.numPages) {
        start--;
        end--;
      }
      while (end - start < numButtons) {
        start--;
      }

      return pages.slice(start, end);
    };

    setPages(makePages());
    setHasPrevious(checkHasPrevious());
    setHasNext(checkHasNext());
  }, [ props.currentPage, props.numButtons, props.numPages ]);

  const paginate = (page: number) => {
    props.onPaginate(props.currentPage, page);
  };

  return (
    <Flex gap0 container={props.container} className={'SwPaginator ' + className}>
      {
        If(props.useFirstLast, () => (
          <Flex>
            <a onClick={() => paginate(0)}>First</a>
          </Flex>
        )).EndIf()
      }
      <Flex>
        <Button small primary={hasPrevious} disabled={!hasPrevious} onClick={() => paginate(props.currentPage - 1)}>
          <IconFA icon={faArrowLeft} />
        </Button>
      </Flex>
      <Flex>
        <Buttons>
          {
            For(pages, (page) => (
              <React.Fragment key={page}>
                {
                  If(page === props.currentPage, () => (
                    <Button small secondary>{page + 1}</Button>
                  ))
                    .Else(() => (
                      <Button small className='Button--primaryOutline' onClick={() => paginate(page)}>{page + 1}</Button>
                    ))
                    .EndIf()
                }
              </React.Fragment>
            ))
          }
        </Buttons>
      </Flex>
      <Flex>
        <Button small primary={hasNext} disabled={!hasNext} onClick={() => paginate(props.currentPage + 1)}>
          <IconFA icon={faArrowRight} />
        </Button>
      </Flex>
      {
        If(props.useFirstLast, () => (
          <Flex>
            <a onClick={() => paginate(props.numPages - 1)}>Last</a>
          </Flex>
        )).EndIf()
      }
    </Flex>
  );
});
