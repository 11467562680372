import { If, IntrinsicProps, createComponent, useModal } from 'react-commons';
import LoginForm from '@/components/forms/LoginForm';
import RegisterForm from '@/components/forms/RegisterForm';
import RequestResetForm from '@/components/forms/RequestResetForm';
import { popoverEvents } from '@/components/Popover';

interface MobileUpsellBannerProps extends IntrinsicProps {
  formVisible: 'login' | 'register' | 'resetPassword'
  setFormVisible: (formVisible: 'login' | 'register' | 'resetPassword') => void
}

export default createComponent<MobileUpsellBannerProps>('MobileUpsellBanner', {}, function MobileUpsellBanner ({},props) {
  const { closeModal } = useModal();

  const closePopover = () => {
    popoverEvents.trigger('closeAll');
    closeModal();
  };
  
  return (
    <>
      {
        If(props.formVisible === 'login', () => (
          <LoginForm
            onSuccess={() => closePopover()}
            onRegisterClicked={() => props.setFormVisible('register')}
            onResetPasswordClicked={() => props.setFormVisible('resetPassword')}
          />
        ))
          .ElseIf(props.formVisible === 'register', () => (
            <RegisterForm
              onSuccess={() => closePopover()}
              onLoginClicked={() => props.setFormVisible('login')}
              onResetPasswordClicked={() => props.setFormVisible('resetPassword')}
            />
          ))
          .ElseIf(props.formVisible === 'resetPassword', () => (
            <RequestResetForm
              onSuccess={() => closePopover()}
              onLoginClicked={() => props.setFormVisible('login')}
              onRegisterClicked={() => props.setFormVisible('register')}
            />
          ))
          .EndIf()
      }
    </>
  );
});
