import { FieldType, Form, Block, InputField, InputType, IconFA, RecaptchaField, Button, Title, CheckboxField, RouterLink } from 'react-commons';
import { useForm } from 'react-commons';
import { useNotifications } from 'react-commons';
import { IntrinsicProps, createComponent } from 'react-commons';
import { faUser, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Slot } from '@twocatmoon/react-slot';
import { If } from '@twocatmoon/react-template-helpers';

import { useLoadingState } from '@/lib/hooks/useLoadingState';
import AuthModel from '@/lib/drupal/models/Auth';
import { useThemeStore } from '@/lib/stores/theme';
import PasswordField from '@/components/PasswordField';

import style from './index.module.scss';
import { useRecaptcha } from '@/lib/hooks/useRecaptcha';

interface RegisterFormProps extends IntrinsicProps {
  captcha?: boolean
  onSuccess?: (data: RegisterFormData) => void
  onResetPasswordClicked?: () => void
  onLoginClicked?: () => void
}

interface RegisterFormData {
  username: string
  email: string
  password: string
  agreeToEmail: boolean
}

export default createComponent<RegisterFormProps>('RegisterForm', { style }, function RegisterForm ({ className }, props) {
  const schema = {
    username: {
      value: '',
      schema: {
        type: FieldType.ANY,
        required: true,
      }
    },
    email: {
      value: '',
      schema: {
        type: FieldType.EMAIL,
        required: true,
        invalidCharacters: [ '+' ],
      }
    },
    password: {
      value: '',
      schema: {
        type: FieldType.PASSWORD,
        required: true,
        minLength: 8,
        criteria: {
          upperAndLower: true,
          number: true,
          specialChars: false,
        }
      }
    },
    passwordAgain: {
      schema: {
        type: FieldType.PASSWORD_VERIFY,
        checkAgainst: 'password'
      }
    },
    agreeToEmail: {
      value: false,
      schema: {
        type: FieldType.BOOLEAN,
        trueValue: true,
        falseValue: false,
      }
    },
    agreeToTerms: {
      value: false,
      schema: {
        type: FieldType.BOOLEAN,
        required: true,
        trueValue: true,
        falseValue: false,
      }
    },
    captchaResult: {
      value: false,
      schema: {
        type: FieldType.BOOLEAN,
        required: !!props.captcha
      }
    }
  };

  const form = useForm(schema);
  const { notify } = useNotifications({ lifeSpan: Number(process.env.NEXT_PUBLIC_NOTIFICATION_LIFESPAN) });
  const [ themeState ] = useThemeStore();
  const [ verifyRecaptcha ] = useRecaptcha();
  const [ isLoading, startLoading, finishLoading ] = useLoadingState();

  const submit = async () => {
    const { data, hasErrors } = form.validate<RegisterFormData>();
    if (hasErrors) return;

    startLoading();

    try {
      await verifyRecaptcha('register');
    } catch (err) {
      notify('There was an error processing your request. Please try again.', 'error');
      finishLoading();
      return;
    }

    try {
      const error = await AuthModel.register(
        data.username, 
        data.email, 
        data.password, 
        data.agreeToEmail,
        themeState.layout
      );
      finishLoading();
      
      if (!error) {
        notify('Your account has been created, please log in.', 'success');
        if (props.onSuccess) props.onSuccess(data);
      } else {
        notify(error, 'error');
      }
    } catch (err) {
      finishLoading();
      notify('Username or email is already in use.', 'error');
    }
  };

  return (
    <Form className={className} onSubmit={submit} style={{ minWidth: '225px' }}>
      <Block>
        <Title size6 h6 className='--textCenter'>Sign Up to Shockwave.com</Title>
      </Block>
      <Block>
        <InputField name='username' type={InputType.ANY} form={form} placeholder='Username' className='Input--medium'>
          <Slot name='icon'><IconFA icon={faUser} /></Slot>
        </InputField>
        <InputField name='email' type={InputType.EMAIL} form={form} placeholder='Email' className='Input--medium'>
          <Slot name='icon'><IconFA icon={faEnvelope} /></Slot>
        </InputField>
        <PasswordField name='password' form={form} placeholder='Password' />
        <PasswordField name='passwordAgain' form={form} placeholder='Password (verify)' />
        <p>
          Passwords must contain at least 1 uppercase letter, 1 lowercase letter, and 1 number, and must be at least 8 characters long.
        </p>
        <br />
        <CheckboxField name='agreeToEmail' form={form}>
          <Slot name='description'>
            Opt-in to email communications?
          </Slot>
        </CheckboxField>
        <CheckboxField name='agreeToTerms' form={form}>
          <Slot name='description'>
            I agree to the <RouterLink href='/about/terms'>Terms of Service</RouterLink> and <RouterLink href='/about/privacyPolicy'>Privacy Policy</RouterLink>.
          </Slot>
        </CheckboxField>
        {
          If(props.captcha, () => (
            <RecaptchaField 
              form={form}
              name='captchaResult' 
              siteKey={process.env.APP_CONFIG.RECAPTCHA_SITEKEY}
            />
          ))
            .EndIf()
        }
      </Block>
      <Block>
        <Button 
          fullWidth 
          submit 
          disabled={form.hasErrors} 
          loading={isLoading} 
          medium 
          secondary
        >
          Sign Up
        </Button>
      </Block>
      <Block className='--textCenter'>
        <p>
          <a 
            className='RouterLink' 
            data-popover-omit
            onClick={(evt) => {
              evt.preventDefault();
              if (props.onResetPasswordClicked) props.onResetPasswordClicked();
            }}
          >
            Forgot Password?
          </a>
        </p>
      </Block>
      <Block className='--textCenter'>
        <p>
          Already have an account?<br />
          <a 
            className='RouterLink'
            data-popover-omit
            onClick={(evt) => {
              evt.preventDefault();
              if (props.onLoginClicked) props.onLoginClicked();
            }}
          >
            Login
          </a>
        </p>
      </Block>
    </Form>
  );
});
