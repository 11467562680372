import FrontendModel from '@/lib/frontend/models/Frontend';
import { useEffect, useRef, useState } from 'react';
import { useLoadScripts } from 'react-commons';

export function useRecaptcha () {
  const recaptcha = useRef(null);
  const [ ready, setReady ] = useState(false);
  
  useLoadScripts([ 
    `https://www.google.com/recaptcha/api.js?onload=recaptchaLoaded&render=${process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY}` 
  ]);

  useEffect(() => {
    if (typeof window !== undefined) {
      // @ts-ignore
      window.recaptchaLoaded = () => {
        // @ts-ignore
        recaptcha.current = window.grecaptcha;
        setReady(true);
      };

      // @ts-ignore
      if (window.grecaptcha) {
        // @ts-ignore
        recaptcha.current = window.grecaptcha;
      }
    }

    if (recaptcha.current) {
      setReady(true);
    }
  }, []);

  const verify = (action: string) => {
    return new Promise<void>((resolve, reject) => {
      const grecaptcha = recaptcha.current;
      if (!grecaptcha) {
        return reject('reCAPTCHA not loaded.');
      }

      grecaptcha.ready(async () => {
        try {
          const token = await grecaptcha.execute(
            process.env.NEXT_PUBLIC_RECAPTCHA_SITEKEY, 
            { action }
          );
          await FrontendModel.verifyRecaptcha(token);
          resolve();
        } catch (err) {
          reject(err);
        }
      });
    });
  };

  return [ verify, ready ] as [ typeof verify, boolean ];
};
