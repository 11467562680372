import { useState, useRef } from 'react';

type UserLoadingStateReturnValue = [
  isLoading: boolean,
  startLoading: (immediate?: boolean) => void,
  finishLoading: () => void,
]

export function useLoadingState (delay = 300): UserLoadingStateReturnValue {
  const [ isLoading, setIsLoadingDispatch ] = useState(false);
  const loadingDebounceRef = useRef<NodeJS.Timeout | null>(null);

  const startLoading = (immediate?: boolean) => {
    clearTimeout(loadingDebounceRef.current!);
    if (immediate) setIsLoadingDispatch(true);
    else loadingDebounceRef.current = setTimeout(() => setIsLoadingDispatch(true), delay);
  };

  const finishLoading = () => {
    clearTimeout(loadingDebounceRef.current!);
    setIsLoadingDispatch(false);
  };

  return [
    isLoading, 
    startLoading,
    finishLoading,
  ];
}
