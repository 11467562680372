import { createComponent, If, IntrinsicProps } from 'react-commons';
import Link from 'next/link';

import style from './index.module.scss';
import GameTileImg from '../GameTileImg';
import { GameData } from '@/lib/drupal/models/Games';
import { MakeOptional } from '@/types';

interface SwGameTileProps extends IntrinsicProps, Omit<MakeOptional<GameData, 'uid' | 'rating' | 'thumbnailImgTall'>, 'thumbnailImgSmall'> {
  tallImg?: boolean
  hideNumPlays?: boolean
}

export default createComponent<SwGameTileProps>('SwGameTile', { style }, function SwGameTile ({ className }, props) {
  const thumbnailUrl = props.tallImg 
    ? props.thumbnailImgTall 
    : props.thumbnailImg;

  return (
    <Link href={props.href}>
      <a className={className}>
        <GameTileImg 
          src={thumbnailUrl} 
          title={props.title}
          tall={props.tallImg}
        />
        {
          If(props.title, () => (
            <>
              <p><strong dangerouslySetInnerHTML={{ __html: props.title }} /></p>
              {
                If (!props.hideNumPlays, () => (
                  <p>{props.numPlays.toLocaleString('en-US')} plays</p>
                )).EndIf()
              }
            </>
          ))
            .EndIf()
        }
      </a>
    </Link>
  );
});

interface SwGameTileSkeletonProps extends IntrinsicProps {
  tallImg?: boolean
  title?: boolean
  isHidden?: boolean
}

export const SwGameTileSkeleton = createComponent<SwGameTileSkeletonProps>('SwGameTile', { style }, function SwGameTileSkeleton ({ className }, props) {
  const thumbnailUrl = props.tallImg 
    ? '/images/gameTilePlaceholderTall.png' 
    : '/images/gameTilePlaceholderRegular.png';

  const classes = [
    className,
    'SwGameTile--skeleton',
  ];
  if (props.isHidden) {
    classes.push('SwGameTile--hidden');
  }

  const finalClassName = classes.join(' ');

  return (
    <div className={finalClassName}>
      <GameTileImg 
        src={thumbnailUrl} 
        title='Game tile placeholder'
        tall={props.tallImg}
        className='--skeletonLoading'
      />
      {
        If(props.title, () => (
          <>
            <p className='--skeletonLoading'>&nbsp;</p>
            <p className='--skeletonLoading'>&nbsp;</p>
          </>
        ))
          .EndIf()
      }
    </div>
  );
});
