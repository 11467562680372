import { FieldType, Form, Block, InputField, InputType, IconFA, RecaptchaField, RecaptchaTheme, Button, Title } from 'react-commons';
import { useForm } from 'react-commons';
import { useNotifications } from 'react-commons';
import { IntrinsicProps, createComponent } from 'react-commons';
import { authActionSets, AuthError, useAuthStore } from '@/lib/drupal/stores/auth';
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { Slot } from '@twocatmoon/react-slot';
import { If } from '@twocatmoon/react-template-helpers';

import style from './index.module.scss';
import { swagSdkEventListener } from '@/lib/hooks/useSwagMessages';
import PasswordField from '@/components/PasswordField';

interface LoginFormProps extends IntrinsicProps {
  captcha?: boolean
  onSuccess?: (data: LoginFormData) => void
  onResetPasswordClicked?: () => void
  onRegisterClicked?: () => void
}

interface LoginFormData {
  username: string,
  password: string
}

export default createComponent<LoginFormProps>('LoginForm', { style }, function LoginForm ({ className }, props) {
  const schema = {
    username: {
      value: '',
      schema: {
        type: FieldType.ANY,
        required: true,
      }
    },
    password: {
      value: '',
      schema: {
        type: FieldType.PASSWORD,
        required: true
      }
    },
    captchaResult: {
      value: false,
      schema: {
        type: FieldType.BOOLEAN,
        required: !!props.captcha
      }
    }
  };

  const form = useForm(schema);
  const { notify } = useNotifications({ lifeSpan: Number(process.env.NEXT_PUBLIC_NOTIFICATION_LIFESPAN) });
  const [ state, _dispatch, execute ] = useAuthStore();

  const submit = async () => {
    const { data, hasErrors } = form.validate<LoginFormData>();
    if (hasErrors) return;

    await execute(authActionSets.authorize({
      username: data.username,
      password: data.password,
      onError: (errorMessage: AuthError) => {
        notify(errorMessage, 'error');
      },
      onSuccess: () => {
        notify('You have been logged in.', 'success');
        swagSdkEventListener.trigger('sendToken');
        if (props.onSuccess) props.onSuccess(data);
      }
    }));
  };

  return (
    <Form className={className} onSubmit={submit} style={{ minWidth: '225px' }}>
      <Block>
        <Title size6 h6 className='--textCenter'>Login to Shockwave.com</Title>
      </Block>
      <Block>
        <InputField name='username' type={InputType.ANY} form={form} placeholder='Email or Screen Name' className='Input--medium'>
          <Slot name='icon'><IconFA icon={faUser} /></Slot>
        </InputField>
        <PasswordField name='password' form={form} placeholder='Password' />
        {
          If(props.captcha, () => (
            <RecaptchaField 
              form={form}
              name='captchaResult' 
              siteKey={process.env.APP_CONFIG.RECAPTCHA_SITEKEY}
            />
          ))
            .EndIf()
        }
      </Block>
      <Block>
        <Button fullWidth submit disabled={form.hasErrors} loading={!state.ready} medium secondary>
          Log In
        </Button>
      </Block>
      <Block className='--textCenter'>
        <p>
          <a 
            className='RouterLink' 
            data-popover-omit
            onClick={(evt) => {
              evt.preventDefault();
              if (props.onResetPasswordClicked) props.onResetPasswordClicked();
            }}
          >
            Forgot Password?
          </a>
        </p>
      </Block>
      <Block className='--textCenter'>
        <p>
          Don't have an account?<br />
          <a 
            className='RouterLink'
            data-popover-omit
            onClick={(evt) => {
              evt.preventDefault();
              if (props.onRegisterClicked) props.onRegisterClicked();
            }}
          >
            Sign Up
          </a>
        </p>
      </Block>
    </Form>
  );
});
