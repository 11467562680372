import Image from 'next/image';
import { createComponent, IntrinsicProps } from 'react-commons';

import style from './index.module.scss';

interface UserAvatarProps extends IntrinsicProps {
  src: string
  displayName?: string
  altText?: string
  small?: boolean
  medium?: boolean
  mediumLarge?: boolean
  selected?: boolean
  noBoxShadow?: boolean
}

const classStates = [
  'small',
  'medium',
  'mediumLarge',
  'selected',
  'noBoxShadow',
];

export default createComponent<UserAvatarProps>('UserAvatar', { classStates, style }, function UserAvatar ({ className }, props) {
  const altText = props.displayName
    ? props.displayName + '\'s Avatar'
    : props.altText || 'User Avatar';

  let size = [ 140, 140 ];

  if (props.small) size = [ 40, 40 ];
  if (props.medium) size = [ 70, 70 ];
  if (props.mediumLarge) size = [ 90, 90 ];

  const style = {
    width: size[ 0 ] + 'px',
    height: size[ 1 ] + 'px',
  };

  return (
    <div className={className} style={style} onClick={props.onClick}>
      <Image 
        src={props.src}      
        alt={altText}
        width={size[ 0 ]}
        height={size[ 1 ]}
      />
    </div>
  );
});
