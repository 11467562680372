import { TaxonomyStub } from '@/lib/drupal/models/Taxonomies';
import Link from 'next/link';
import { IntrinsicProps, createComponent, Flex, For } from 'react-commons';

import style from '../index.module.scss';

interface GenreIconButtonProps extends IntrinsicProps {
  genre: Omit<TaxonomyStub, 'uid'>
  isDark?: boolean
}

export const GenreIconButton = createComponent<GenreIconButtonProps>('GenreIconButton', { style }, function GenreIconButton ({}, props) {
  return (
    <Link href={props.genre.href}>
      <a>
        <img 
          src={`/images/genreIcons/${props.isDark ? 'dark/' : ''}${props.genre.icon}.svg`} 
          alt={props.genre.name}
        />
        <span>{props.genre.name}</span>
      </a>
    </Link>
  );
});

interface GenreIconButtonsProps extends IntrinsicProps {
  buttonKey: string
  genres: TaxonomyStub[]
  excludedGenreIds?: string[]
  isDark?: boolean
}

export const GenreIconButtons = createComponent<GenreIconButtonsProps>('GenreIconButtons', { style }, function GenreIconButtons ({ className, slots }, props) {
  const excludedIds = props.excludedGenreIds || [];
  const genres = props.genres
    .filter((genre) => !excludedIds.includes(genre.uid));

  return (
    <Flex className={className} container gap1 wrap alignCenter justifyCenter>
      <>
        {slots.before}
        {
          For(genres, (genre, index) => (
            <GenreIconButton 
              key={`${genre.uid}_${index}_${props.buttonKey}`} 
              genre={genre} 
              isDark={props.isDark}
            />
          ))
        }
        {slots.after}
      </>
    </Flex>
  );
});
