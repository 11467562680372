import axios from '@/lib/frontend/api/axiosInstance';
import { AxiosResponse } from 'axios';
import { logErrorEvent } from '@/lib/util/logErrorEvent';

export default class FrontendModel {
  static async verifyRecaptcha (token: string): Promise<void> {
    let response: AxiosResponse;
    try {
      response = await axios.request({
        method: 'post',
        url: '/recaptcha/verify',
        data: { token }
      });
    } catch (error) {
      logErrorEvent('Verify Recaptcha', false, error);
      throw error;
    }

    return response.data;
  }
};
