import { createComponent, IntrinsicProps } from 'react-commons';
import Image from 'next/image';

import style from './index.module.scss';
import { useEffect, useRef, useState } from 'react';
import { getThumbnail, ThumbnailSize } from '@/lib/drupal/models/Games';

interface GameTileImgProps extends IntrinsicProps {
  src: string
  title: string
  tall?: boolean
  small?: boolean
  extraSmall?: boolean
  extraLarge?: boolean
  gamePodLarge?: boolean
  priority?: boolean
}

export default createComponent<GameTileImgProps>('GameTileImg', { style }, function GameTileImg ({ className }, props) {
  const ref = useRef(null);

  let size = [ 122, 92 ];
  if (props.tall) size = [ 122, 182 ];
  if (props.small) size = [ 92, 70 ];
  if (props.extraSmall) size = [ 74, 56 ];
  if (props.extraLarge) size = [ 272, 182 ];

  const style = {
    width: size[ 0 ] + 'px',
    height: size[ 1 ] + 'px',
  };

  let thumbnailSize: ThumbnailSize = 'regular';
  if (props.tall) thumbnailSize = 'tall';
  if (props.small) thumbnailSize = 'small';
  if (props.extraLarge) thumbnailSize = 'xl';

  const hasStartedLoadingRef = useRef(false);
  const [ isLoaded, setIsLoaded ] = useState(false);
  useEffect(() => {
    if (hasStartedLoadingRef.current) return;
    hasStartedLoadingRef.current = true;
    
    const img = document.createElement('img');
    img.src = props.src;
    img.onload = () => setIsLoaded(true);
  }, [ props.src ]);

  return (
    <figure 
      ref={ref} 
      className={className + ' ' + (isLoaded ? 'GameTileImg--ready' : '')} 
      onClick={props.onClick} 
      style={style}
    >
      <Image
        src={getThumbnail(undefined, thumbnailSize)}
        alt={props.title + ' thumbnail'}
        width={size[ 0 ]}
        height={size[ 1 ]}
        draggable={false}
        priority={props.priority}
        quality={process.env.APP_CONFIG.IMAGE_QUALITY}
        aria-hidden={true}
      />
      <Image
        src={props.src}
        alt={props.title + ' thumbnail'}
        width={size[ 0 ]}
        height={size[ 1 ]}
        draggable={false}
        priority={props.priority}
        quality={process.env.APP_CONFIG.IMAGE_QUALITY}
      />
    </figure>
  );
});
