import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ReactNode, useState } from 'react';
import { If, InputField, InputType, Slot, IconFA } from 'react-commons';

interface PasswordFieldProps {
  form: any
  name: string
  placeholder: string
  label?: string
}

export default function PasswordField (props: PasswordFieldProps) {
  const [ hidden, setHidden ] = useState(true);
  
  return (
    <>
      {
        If(hidden, () => (
          <InputField name={props.name}
            type={InputType.PASSWORD} 
            form={props.form} 
            placeholder={props.placeholder}
            className='Input--medium'
          >
            { If(props.label, () => (<Slot name='label'>{props.label}</Slot>)).EndIf() }
            <Slot name='icon'>
              <IconFA icon={faEye} onClick={() => setHidden(!hidden)} />
            </Slot>
          </InputField>
        )).Else(() => (
          <InputField name={props.name}
            type={InputType.ANY} 
            form={props.form} 
            placeholder={props.placeholder}
            className='Input--medium'
          >
            { If(props.label, () => (<Slot name='label'>{props.label}</Slot>)).EndIf() }
            <Slot name='icon'>
              <IconFA icon={faEyeSlash} onClick={() => setHidden(!hidden)} />
            </Slot>
          </InputField>
        )).EndIf()
      }
    </>
  );
}
