import { FullGameData } from '@/lib/drupal/models/Games';
import { useAuthStore } from '@/lib/drupal/stores/auth';
import { EventMap, EventListener } from '@/lib/util/eventListener';
import axios from 'axios';
import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';

interface UseSwagMessagesOptions {
  game: FullGameData
  gameSlug: string
  onToggleFullscreen?: () => void
  onShowShareDialog?: () => void
  onShowLoginDialog?: () => void
}

export type SWAGSDKEvents = EventMap & {
  'sendToken': () => void;
}

class SWAGSDKEventListener extends EventListener<SWAGSDKEvents> {}
export const swagSdkEventListener = new SWAGSDKEventListener();

async function requestToken (memberId: any, memberIdHash: any, displayName: string) {
  let token;
  try {
    const queryParams = new URLSearchParams({
      jwt: '1',
      shockwave_logged_in: memberId ? '1' : '0',
      screen_name: displayName || '',
      site_member_id: memberId,
      site_member_id_hash: memberIdHash,
    });
    const response = await axios.get(
      process.env.NEXT_PUBLIC_SWAG_API_URL + 
      '/token?' + 
      queryParams.toString(),
      {
        headers: {
          'Cache-Control': 'no-cache',
          'Content-Type': 'application/json',
        },
      }
    );
    token = response.data;
  } catch {
    throw new Error('Failed to get token from API');
  }

  let user;
  try {
    const response = await axios.get(process.env.NEXT_PUBLIC_SWAG_API_URL + '/user', {
      headers: {
        'x-member-token': token,
        'Cache-Control': 'no-cache',
        'Content-Type': 'application/json',
      }
    });
    user = response.data;
  } catch {
    throw new Error('Failed to get user from API');
  }

  return btoa(JSON.stringify(user));
}

export function useSwagMessages (options: UseSwagMessagesOptions) {
  const [ authState ] = useAuthStore();
  const router = useRouter();
  const shouldSendTokenRef = useRef(false);

  const completeTokenRequest = async (source: Window, origin: string) => {
    if (!authState.ready) {
      const payload = {
        eventName: 'onTokenReceived',
        message: 'Auth state not ready',
      };
      source.postMessage(
        JSON.stringify(payload), 
        origin
      );
      return;
    };

    let loginToken;
    try {
      loginToken = await requestToken(
        authState.user?.memberId || '', 
        authState.user?.memberIdHash || '',
        authState.user?.displayName || ''
      );
    } catch (err) {
      const payload = {
        eventName: 'onTokenError',
        message: err.message,
      };
      source.postMessage(
        JSON.stringify(payload), 
        origin
      );
      return;
    }

    const payload = {
      eventName: 'onTokenReceived',
      message: loginToken,
    };
    source.postMessage(
      JSON.stringify(payload), 
      origin
    );
    return;
  };

  useEffect(() => {
    let messageHandler;

    window.addEventListener('message', messageHandler = async (event) => {
      if (!event || typeof event.data !== 'string') return;

      try {
        const { eventName } = JSON.parse(event.data);

        switch (eventName) {
        case 'toggleFullscreen': {
          options.onToggleFullscreen?.();
          return;
        }
        case 'showShareDialog': {
          options.onShowShareDialog?.();
          return;
        }
        case 'showLoginDialog': {
          options.onShowLoginDialog?.();
          return;
        }
        case 'navigateToArchive': {
          if (options.game.archive?.display === 'none') return;
          router.push(`/gamelanding/${options.gameSlug}/archive`);
          return;
        };
        case 'navigateToGameLanding': {
          router.push(`/gamelanding/${options.gameSlug}`);
          return;
        }
        case 'requestToken': {
          completeTokenRequest(event.source, event.origin);
          return;
        }
        }
      } catch (err) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
          console.warn(err);
        }
      }
    });

    let tokenReceivedHandler;
    swagSdkEventListener.on('sendToken', tokenReceivedHandler = async () => {
      shouldSendTokenRef.current = true;
    });

    return () => {
      window.removeEventListener('message', messageHandler);
      swagSdkEventListener.off('sendToken', tokenReceivedHandler);
    };
  });

  useEffect(() => {
    if (!authState.ready) return;

    const childWindow = document
      .getElementsByClassName('GamePlayer__Game')[ 0 ]
      ?.getElementsByTagName('object')[ 0 ]
      ?.contentWindow;

    if (!childWindow) return;

    shouldSendTokenRef.current = false;
    completeTokenRequest(childWindow, '*');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ authState.ready ]);
}
