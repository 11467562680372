import { FieldType, Form, Block, InputField, InputType, IconFA, RecaptchaField, RecaptchaTheme, Button, Title } from 'react-commons';
import { useForm } from 'react-commons';
import { useNotifications } from 'react-commons';
import { IntrinsicProps, createComponent } from 'react-commons';
import { authActionSets, AuthError, useAuthStore } from '@/lib/drupal/stores/auth';
import { faUser, faLock, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Slot } from '@twocatmoon/react-slot';
import { If } from '@twocatmoon/react-template-helpers';

import style from './index.module.scss';
import AuthModel from '@/lib/drupal/models/Auth';
import { useLoadingState } from '@/lib/hooks/useLoadingState';
import { useState } from 'react';

interface RequestResetForm extends IntrinsicProps {
  captcha?: boolean
  onSuccess?: (data: RequestResetFormData) => void
  onLoginClicked?: () => void
  onRegisterClicked?: () => void
}

interface RequestResetFormData {
  email: string
}

export default createComponent<RequestResetForm>('RequestResetForm', { style }, function RequestResetForm ({ className }, props) {
  const [ authState ] = useAuthStore();

  const schema = {
    email: {
      value: (authState.ready && authState.user?.email) || '',
      schema: {
        type: FieldType.EMAIL,
        required: true,
        invalidCharacters: [ '+' ],
      }
    },
    // captchaResult: {
    //   value: false,
    //   schema: {
    //     type: FieldType.BOOLEAN,
    //     required: !!props.captcha
    //   }
    // }
  };

  const form = useForm(schema);
  const { notify } = useNotifications({ lifeSpan: Number(process.env.NEXT_PUBLIC_NOTIFICATION_LIFESPAN) });
  const [ state, _dispatch, execute ] = useAuthStore();
  const [ isLoading, startLoading, finishLoading ] = useLoadingState();
  const [ hasSubmitted, setHasSubmitted ] = useState(false);

  const submit = async () => {
    const { data, hasErrors } = form.validate<RequestResetFormData>();
    if (hasErrors) return;

    startLoading();

    try {
      await AuthModel.requestResetPassword(data.email);
      finishLoading();
      setHasSubmitted(true);
      notify('Please check your email for further instructions.', 'success');
    } catch (err) {
      finishLoading();
      notify(err.message, 'error');
    }
  };

  return (
    <Form className={className} onSubmit={submit} style={{ minWidth: '225px' }}>
      <Block>
        <Title size6 h6 className='--textCenter'>Request Password Reset</Title>
      </Block>
      <Block>
        <InputField name='email' type={InputType.EMAIL} form={form} placeholder='Email' className='Input--medium'>
          <Slot name='icon'><IconFA icon={faEnvelope} /></Slot>
        </InputField>
        {
          If(props.captcha, () => (
            <RecaptchaField 
              form={form}
              name='captchaResult' 
              siteKey={process.env.APP_CONFIG.RECAPTCHA_SITEKEY}
            />
          ))
            .EndIf()
        }
      </Block>
      <Block>
        <Button 
          fullWidth 
          submit 
          disabled={form.hasErrors || hasSubmitted} 
          loading={!state.ready || isLoading} 
          medium 
          secondary
        >
          Request Reset
        </Button>
      </Block>
      <Block className='--textCenter'>
        <p>
          Already have an account?<br />
          <a 
            className='RouterLink'
            data-popover-omit
            onClick={(evt) => {
              evt.preventDefault();
              if (props.onLoginClicked) props.onLoginClicked();
            }}
          >
            Login
          </a> 
          &nbsp;or&nbsp;
          <a 
            className='RouterLink'
            data-popover-omit
            onClick={(evt) => {
              evt.preventDefault();
              if (props.onRegisterClicked) props.onRegisterClicked();
            }}
          >
            Sign Up
          </a>
        </p>
      </Block>
    </Form>
  );
});
