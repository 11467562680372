import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { If, IntrinsicProps, createComponent } from 'react-commons';

interface PerDeviceProps extends IntrinsicProps {
  redirectOnMobile?: boolean
  useDisplay?: boolean
}

export function isMobile (useDisplay?: boolean) {
  if(typeof window === 'undefined') {
    return false;
  }
  
  const query = useDisplay 
    ? '(max-width: 768px)'
    : '(max-device-width: 750px)';

  return (
    !!window.matchMedia(query).matches
  );
};

export default createComponent<PerDeviceProps>('PerDevice', {}, function PerDevice ({ slots }, props) {
  const router = useRouter();

  useEffect(() => {
    if (props.redirectOnMobile && isMobile(props.useDisplay)) {
      router.push('/');
    }
  }, [ router, props.redirectOnMobile, props.useDisplay ]);

  return (
    <>
      {
        If(slots.mobile, () => (
          <div className={(props.className ? `${props.className} ` : '') + (props.useDisplay ? '--hideOnDesktopDisplays' : '--hideOnDesktopDevices')}>
            {slots.mobile}
          </div>
        )).EndIf()
      }
      <div className={(props.className ? `${props.className} ` : '') + (props.useDisplay ? '--hideOnMobileDisplays' : '--hideOnMobileDevices')}>
        {slots.desktop}
      </div>
    </>
  );
});
